import axios from "axios";
const REACT_APP_API_KEY_PLAYER = "https://player-service.pokerburda.com/";
const REACT_APP_API_KEY_MAINGAME_TOKEN =
  "e82fee67ceb1357fb435f62b45761ccffb296b00a53596b14cd2b1c2b463fd4afcf331effc57f8f093bc95074cb21b5e289385b47d357cadb77dbc9a47302987079cc49c115af31a55a9a82e1985f36b3060c23b48d66f8594043d8eca8f8b7f9c0db74d6676ae9b7aeca65ba8f713a533e39777e9511cdc1fc7d643303eda0f";
const API_URL = REACT_APP_API_KEY_PLAYER;
const url = "users/getUserInfo";
const token = REACT_APP_API_KEY_MAINGAME_TOKEN;

const postVaultData = async () => {
  const uID = localStorage.getItem("uID");
  let data = JSON.stringify({
    vault: 0,
    uID: uID,
  });
  console.log("postVaultData");
  console.log(data);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_URL}${url}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    console.log(JSON.stringify(response.data));
    if (response.data.status === 200) {
      return response.data.data.vault;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { postVaultData };
