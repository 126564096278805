import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
// import SelectGame from 'pages/extra-pages/SelectGame';

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const SelectGame = Loadable(lazy(() => import("pages/extra-pages/SelectGame")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/",
      element: <SelectGame />,
    },
  ],
};

export default LoginRoutes;
