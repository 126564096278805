// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { useEffect, useState } from "react";
import axios from "axios";
import { postVaultData } from "components/playerservice";

import { useDispatch, useSelector } from "react-redux";
import { setItem } from "store/reducers/vault";
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [jwt, setJwt] = useState(null);
  // const [vault, setVault] = useState(0);

  const unAuthRoutes = ["/login"];

  const vault = useSelector((state) => state.vault.vault); // Vault değerini seçin
  const dispatch = useDispatch();

  const updateVault = (newVaultValue) => {
    dispatch(setItem(newVaultValue)); // Vault değerini güncelleyin
  };
  useEffect(() => {
    checkLoggedInStatus();
  });
  useEffect(() => {
    const fetchData = async () => {
      if (loggedIn) {
        let vlt = await postVaultData();
        console.log("vlt");
        console.log(vlt);
        if (vlt) {
          dispatch(setItem(vlt));
        }
      }
    };

    fetchData();
  }, [loggedIn, dispatch]);

  const checkLoggedInStatus = () => {
    // console.log(localStorage.getItem("uJwt"));
    // console.log(localStorage.getItem("uID"));
    // console.log(!localStorage.getItem("uJwt") || !localStorage.getItem("uID"));
    // console.log(unAuthRoutes.includes(window.location.pathname));
    if (!unAuthRoutes.includes(window.location.pathname)) {
      if (!localStorage.getItem("uJwt") || !localStorage.getItem("uID")) {
        window.location.href = "/login";
      }
      setLoggedIn(true);
    }
  };
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes vault={vault} />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
