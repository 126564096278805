// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
let initialState = {
  vault: 0,
};

// ==============================|| SLICE - MENU ||============================== //

const vault = createSlice({
  name: "vault",
  initialState,
  reducers: {
    setItem(state, action) {
      console.log("action");
      console.log(action);

      state.vault = action.payload;
    },
  },
});

export default vault.reducer;

export const { setItem } = vault.actions;
